// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: "Marena",
  production: true,

  //  apiUrl: 'http://dev-api.manipal.edu/',
  apiUrl: "https://api.manipal.edu/",
  apiUrl1: "http://43.205.223.7:5000/",

  oneSignalAppId: "b48d5f14-a656-4383-b34e-725affb0180f", //dev
  media_domain: "https://d2dkf813gb84zq.cloudfront.net/production", //dev
  apiPrefix: "api/v1",
  imgWidth: "500",
  imgHeight: "500",
  servImgWidth: "720",
  servImgHeight: "480",
  servCovImgWidth: "360",
  servCovImgHeight: "200",
  banImgWidth: "64",
  banImgHeight: "64",
  gameTypeWidth: "72",
  gameTypeHeight: "72",
  bannerWidth: "930",
  bannerHeight: "480",
  IdWidth: "265",
  IdHeight: "190",
};
